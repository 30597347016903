import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

export default styled(motion.h1)`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: ${(props) => props.color};
  width: 70%;
`;
