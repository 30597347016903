import React from "react";
import classes from "./Card.module.css";
// MUI
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
// import { Grid, ButtonBase, IconButton } from "@material-ui/core";
// import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
// MUI
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import VideocamIcon from "@material-ui/icons/Videocam";
import UpdateIcon from "@material-ui/icons/Update";
import ImageIcon from "@material-ui/icons/Image";

const Card = (props) => {
  const icon =
    props.tags === "Vidéo" ? (
      <VideocamIcon style={{ fontSize: ".8rem" }} />
    ) : props.tags === "Avant / Aprés" ? (
      <UpdateIcon style={{ fontSize: ".8rem" }} />
    ) : props.tags === "Illustration" ? (
      <ImageIcon style={{ fontSize: ".8rem" }} />
    ) : (
      <EditOutlinedIcon style={{ fontSize: ".8rem" }} />
    );

  const handleCardOpen = () => {
    props.handleOpen(true);
    props.executeScroll();
  };

  const personnalisableTag = !props.submitted ? (
    <div className={classes.Tag} style={{ backgroundColor: "#3D9DC7" }}>
      <EditOutlinedIcon style={{ fontSize: ".8rem" }} />
      <p>Personnalisable</p>
    </div>
  ) : (
    <div className={classes.Tag} style={{ backgroundColor: "#38BB6E" }}>
      <EditOutlinedIcon style={{ fontSize: ".8rem" }} />
      <p>Personnalisé !</p>
    </div>
  );

  return (
    <div
      style={props.submitted ? { border: "3px solid #38BB6E" } : null}
      className={classes.Card}
      onClick={handleCardOpen}
    >
      {props.submitted ? (
        <div className={classes.Notification}>
          <CheckIcon
            style={{
              fontSize: "1.3rem",
              color: "white",
              marginLeft: "-.1rem",
            }}
          />
        </div>
      ) : null}
      <div className={classes.Thumbnail}>
        <img
          alt="thumbnail"
          src={require(`../../../../assets/travaux/${props.imgSrc}`)}
        />
      </div>

      <div className={classes.Main}>
        <div className={classes.Titre}>
          <p>
            <span>{props.titleStrong}</span> {props.titleWeak}
          </p>
        </div>
        <div className={classes.Description}>
          <span> {props.description}</span>
        </div>

        <div className={classes.TagsContainer}>
          <div className={classes.TagContainer}>
            {!props.submitted ? (
              <div
                className={classes.Tag}
                style={{ backgroundColor: "#3D9DC7" }}
              >
                {icon}
                <p>{props.tags}</p>
              </div>
            ) : (
              <div
                className={classes.Tag}
                style={{ backgroundColor: "#38BB6E" }}
              >
                <DoneAllIcon style={{ fontSize: "1rem" }} />
                {/* {icon} */}
                <p>Vu !</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={classes.Icon}>
        <KeyboardArrowRightRoundedIcon style={{ fontSize: "1.8rem" }} />
      </div>
    </div>
  );
};

export default Card;
