import React from "react";
// Material Components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    textAlign: "left",
    fontSize: "1rem",
  },
}));

const DropDown = (props) => {
  const matClasses = useStyles();

  const years = [];
  for (let i = 2020; i > 1900; i--) {
    years.push(i);
  }

  return (
    <FormControl className={matClasses.formControl}>
      <InputLabel id="demo-simple-select-label">Année</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.value}
        onChange={props.handleChange}
      >
        {years.map((year) => (
          <MenuItem value={year} key={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
