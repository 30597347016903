import React, { useContext } from "react";
// CONTEXTS
import { ProfileSetupContext } from "../../../contexts/ProfileSetupContext";
// MUI COMPONENTS
import Radio from "../../muiComponents/Radio/Radio";

const QuestionFour = (props) => {
  const { profileSetupData, dispatch } = useContext(ProfileSetupContext);

  return (
    <div>
      <Radio
        {...profileSetupData.numCars}
        value={profileSetupData.numCars.value}
        changed={(e) =>
          dispatch({ type: "SET_NUMCARS", numCars: e.target.value })
        }
      >
        Combien de voiture avez-vous dans votre foyer ?
      </Radio>
    </div>
  );
};

export default QuestionFour;
