import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { withRouter } from "react-router";

// const ModalContainer = styled(motion.div)`
//   position: fixed;
//   z-index: 500;
//   background: white;
//   overflow-y: scroll;
//   width: 100%;
//   height: 100%;
//   box-sizing: border-box;
//   transition: all 0.3s ease-out;
// `;

// const Modal = ({ children }) => {
//   return <ModalContainer>{children}</ModalContainer>;
// };

// export default Modal;
const InnerModal = styled(motion.div)`
  background-color: white;
  outline: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media screen and (min-width: 910px) {
    /* padding: 2rem 2rem; */
    border-radius: 20px;
    width: 450px;
    height: 90%;
    outline: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CardModal = (props) => {
  const classesMUI = useStyles();

  if (props.width < 910) {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{ overflow: "scroll" }}
          open={props.show}
          onClose={() => props.history.push("/travaux")}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <InnerModal layoutId="InnerModal">{props.children}</InnerModal>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesMUI.modal}
          open={props.show}
          onClose={() => props.history.push("/travaux")}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <InnerModal layoutId="InnerModal">{props.children}</InnerModal>
        </Modal>
      </>
    );
  }
};

export default withRouter(CardModal);
