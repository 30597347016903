import React from "react";
import classes from "./Switch.module.css";
import { motion } from "framer-motion";
import styled from "styled-components";

const Off = styled(motion.span)`
  position: absolute;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${(props) => (props.isToggled ? "#fff" : "#3c3c3c")};
  top: 18px;
  left: 30px;
`;
const On = styled(motion.span)`
  position: absolute;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${(props) => (props.isToggled ? "#fff" : "#fff")};
  top: 18px;
  right: 30px;
`;

const Switch = ({ isToggled, onToggle }) => {
  return (
    <label className={classes.switch}>
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className={classes.slider} />
      <Off isToggled={isToggled}>Avant</Off>
      <On isToggled={isToggled}>Après</On>
    </label>
  );
};

export default Switch;
