import React, { useReducer, createContext } from "react";
import { ContactReducer } from "../reducers/ContactReducer";

export const ContactContext = createContext();

const ContactContextProvider = ({ children }) => {
  const [contactData, dispatch] = useReducer(ContactReducer, {
    email: {
      elementType: "Input",
      elementConfig: {
        type: "text",
        placeholder: "Votre email",
      },
      validation: {
        required: true,
        regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
      value: "",
    },
    message: {
      elementType: "textarea",
      elementConfig: {
        type: "textarea",
        placeholder: "Votre message",
      },
      validation: {
        required: true,
      },
      value: "",
    },
    helperText: {
      type: "",
      message: "",
    },
  });

  return (
    <ContactContext.Provider value={{ contactData, dispatch }}>
      {children}
    </ContactContext.Provider>
  );
};

export default ContactContextProvider;
