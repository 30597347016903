import React, { useContext } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Wrapper from "../elements/CardWrapper";
import Title from "../elements/CardTitle";
import Description from "../elements/CardDescription";
import NextButton from "../elements/NextButton";
import BackButton from "../elements/BackButton";
import CardModal from "../layouts/CardModal";
// Router
import { useParams } from "react-router-dom";
// Contexts
import { TravauxContext } from "../../contexts/TravauxContext";

const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const ImageWrapper = styled(motion.div)`
  margin: 0 auto;
  max-width: 20rem;
`;

const CardIntro = (props) => {
  const { travauxData, dispatch } = useContext(TravauxContext);
  const { cards, selectedCard, showCard, filters } = travauxData;
  const { width, currentUser } = props;
  let { cardIndex } = useParams();
  let card = cards[cardIndex];
  return (
    <CardModal width={width} dispatch={dispatch} show={true}>
      <>
        <Wrapper variant="cardIntro">
          <Title
            layoutId="title"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            color="#282828"
          >
            {card.titleStrong} {card.titleWeak}
          </Title>
          <ImageWrapper layoutId="imageIllustration">
            <img
              alt={card.titleStrong}
              src={require(`../../assets/travaux/${card.thumbnail}`)}
              style={{ width: "100%" }}
            />
          </ImageWrapper>
          <Description variant="cardIntro" layoutId="description">
            {card.description}
          </Description>
          <ButtonWrapper>
            <NextButton href={`/travaux/${cardIndex}/0`}>
              Explorer / Personnaliser
            </NextButton>
          </ButtonWrapper>
        </Wrapper>
        <BackButton href="/travaux">Revenir aux fiches</BackButton>
      </>
    </CardModal>
  );
};

export default CardIntro;
