import React, { useState } from "react";
import classes from "./UploadDocument.module.css";
import { storage } from "../../../../config/firebase";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import { Button, TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
// utils
import { submitPdf, loadPdfs } from "../../../../utils/TravauxUtils";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const UploadDocument = ({ claims, setPdfs }) => {
  const classesMUI = useStyles();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleEditDocument = (inputId) => {
    const fileInput = document.getElementById(inputId);
    fileInput.click();
  };

  const handleChange = (e) => {
    if (
      e.target.files[0] &&
      e.target.files[0].name.split(".")[
        e.target.files[0].name.split(".").length - 1
      ] !== "pdf"
    ) {
      alert("Veuillez charger un document PDF");
    } else {
      setPdfFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    const pdfExtension = pdfFile.name.split(".")[
      pdfFile.name.split(".").length - 1
    ];
    const pdfName = `${Math.round(
      Math.random() * 100000000000
    )}.${pdfExtension}`;
    const uploadTask = storage.ref(`pdfs/${pdfName}`).put(pdfFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        progress === 100 && setShow(false);
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("pdfs")
          .child(pdfName)
          .getDownloadURL()
          .then((url) => {
            setUrl(url);
            submitPdf(
              {
                title: title,
                type: "pdf",
                url: url,
              },
              claims.user_id
            );
            setLoading(false);
            loadPdfs(setPdfs);
          });
      }
    );
  };

  return (
    <div>
      <div className={classes.AddButton}>
        <p onClick={() => setShow(true)}>Ajouter un PDF</p>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classesMUI.modal}
        open={show}
        onClose={() => setShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <div className={classes.ModalContent}>
            <h3 style={{ color: "#3B4578", marginBottom: "2rem" }}>
              Ajouter un document PDF
            </h3>
            <div className={classes.QuestionTitle}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 40, color: "#3B4578" }}
              />
              <p>
                Donnez un titre à votre article.{" "}
                <span style={{ fontSize: ".7rem" }}>*obligatoire</span>
              </p>
            </div>
            <div className={classes.TitleField}>
              <TextField
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                variant="outlined"
                inputProps={{
                  maxLength: 100,
                }}
                label=""
                placeholder="Donnez ici un titre à votre article."
                style={{ marginBottom: "0.8em", width: "100%" }}
                color="primary"
              />
            </div>
            <div className={classes.InputFile}>
              <input
                type="file"
                id="pdfInput"
                hidden="hidden"
                onChange={(e) => handleChange(e, "pdfInput")}
              />
              <div
                className={classes.CloudIcon}
                onClick={() => handleEditDocument("pdfInput")}
              >
                <CloudUploadRoundedIcon
                  style={{ fontSize: "5rem", cursor: "pointer" }}
                  color="primary"
                />
                {pdfFile ? <p>{pdfFile.name}</p> : <p>Veuillez cliquer ici</p>}
              </div>
            </div>
            {loading && (
              <div style={{ margin: "1rem 0" }}>
                <LinearProgress variant="determinate" value={progress} />
              </div>
            )}
            <div className={classes.Buttons}>
              <Button
                color="primary"
                size="medium"
                style={{
                  width: "6rem",
                  color: "#333333",
                  backgroundColor: "#E0E0E0",
                }}
                onClick={() => setShow(false)}
              >
                Retour
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled={title.trim() === "" || !pdfFile || loading}
                style={{ width: "8rem", color: "white" }}
                onClick={() => {
                  handleUpload();
                  setLoading(true);
                }}
              >
                Valider
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default UploadDocument;
