import { createMuiTheme } from "@material-ui/core/styles";

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#3B4578",
    },
    secondary: {
      main: "#3D9DC7",
    },
  },
  typography: {
    fontFamily: "'PT Sans', sans-serif",
  },
});

export default muiTheme;
