import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const CardIntroDescription = styled(motion.p)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 120%;
  color: #898786;
`;

const CardMainDescription = styled(motion.p)`
  position: relative;
  background: #f9f9f9;
  border-radius: 20px;
  padding: 2rem;
  margin: 0 2rem;
  box-shadow: inset 0px -7px 0px #bebebe;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: #000000;
`;

const Description = ({ children, variant }) => {
  let description;
  switch (variant) {
    case "cardIntro":
      description = <CardIntroDescription>{children}</CardIntroDescription>;
      break;
    case "cardMain":
      description = <CardMainDescription>{children}</CardMainDescription>;
      break;
    default:
      description = <p>Wrong Description Variant</p>;
      console.error("Wrong description variant");
      break;
  }

  return description;
};

export default Description;
