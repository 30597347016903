import React from "react";
import classes from "./LongButton.module.css";

const LongButton = (props) => {
  return (
    <button
      className={props.styledButton ? props.styledButton : classes.LongButton}
      onClick={props.clicked}
    >
      {props.children}
    </button>
  );
};

export default LongButton;
