import React, { useState, useCallback } from "react";
import classes from "./ReclamationContent.module.css";
// MUI
import { Button, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
// ImageViewer
import ImageViewer from "react-simple-image-viewer";

const AdminReclamationContent = ({
  setShow,
  tagOne,
  tagTwo,
  title,
  description,
  pictures,
  adminAnswer,
  existingAnswer,
  setAdminAnswer,
  submitHandler,
  deleteHandler,
}) => {
  // Image Viewer
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  let picturesUrls = [];
  pictures &&
    pictures.map(
      (picture) => picture.url !== "" && picturesUrls.push(picture.url)
    );

  return (
    <div className={classes.StepContent}>
      <div className={classes.RecapTags}>
        {tagOne && <p>{tagOne}</p>}
        {tagOne && <p>{tagTwo}</p>}
      </div>
      <div className={classes.RecapTitle}>
        <p style={{ fontWeight: "700", color: "#3B4578", fontSize: ".9rem" }}>
          Titre de la demande
        </p>
        <div className={classes.TitleArea}>
          <p>{title}</p>
        </div>
      </div>
      <div className={classes.RecapDescription}>
        <p style={{ fontWeight: "700", color: "#3B4578", fontSize: ".9rem" }}>
          Description de la demande
        </p>
        <div className={classes.DescriptionArea}>
          <p>{description}</p>
        </div>
      </div>
      <div className={classes.RecapImagesRow}>
        {pictures.map((option, index) => {
          return option.url ? (
            <div key={option.id}>
              <div
                onClick={() => openImageViewer(index)}
                className={classes.ImageUploaded}
                style={{
                  backgroundImage: `url('${option.url}')`,
                }}
              ></div>
            </div>
          ) : null;
        })}
        {isViewerOpen && (
          <ImageViewer
            src={picturesUrls}
            currentIndex={currentImage}
            onClose={closeImageViewer}
          />
        )}
      </div>
      {existingAnswer ? (
        <div
          className={classes.RecapDescription}
          style={{ marginBottom: "1rem" }}
        >
          <p style={{ fontWeight: "700", color: "#3B4578", fontSize: ".9rem" }}>
            Réponse de l’entreprise :
          </p>
          <div className={classes.DescriptionArea}>
            <p>{existingAnswer}</p>
          </div>
        </div>
      ) : (
        <div className={classes.RecapDescription}>
          <p style={{ fontWeight: "700", color: "#3B4578", fontSize: ".9rem" }}>
            Réponse de l'équipe chantier :
          </p>
          <div className={classes.DescriptionField}>
            <TextField
              onChange={(e) => setAdminAnswer(e.target.value)}
              value={adminAnswer}
              variant="outlined"
              inputProps={{
                maxLength: 500,
              }}
              placeholder="Ecrivez ici votre réponse (500 caractères maximum.)"
              label=""
              style={{ marginBottom: "0.8em", width: "100%" }}
              color="primary"
              multiline
              rows={4}
            />
          </div>
        </div>
      )}

      <div className={classes.Buttons}>
        <Button
          color="primary"
          size="medium"
          style={{
            width: "6rem",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
          onClick={() => {
            setShow(false);
            setAdminAnswer("");
          }}
        >
          Retour
        </Button>
        <Tooltip title="Supprimer définitivement la demande" arrow>
          <IconButton aria-label="supprimer" onClick={deleteHandler}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={adminAnswer.trim() === ""}
          style={{ width: "6rem", color: "white" }}
          onClick={submitHandler}
        >
          Valider
        </Button>
      </div>
    </div>
  );
};

export default AdminReclamationContent;
