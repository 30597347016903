export const codes = [
  "g63r07Y",
  "n595I3k",
  "957o1kL",
  "w934s9D",
  "29gK82b",
  "0dz964M",
  "61z2Qk9",
  "1u1Y860",
  "2450tX9",
  "2w42U3q",
  "PCk5960",
  "na02L65",
  "8g5Hi66",
  "2Wh68M9",
  "2k96gD5",
  "1Q6i6x6",
  "U7n99z5",
  "Km190Q1",
  "Xk7602E",
  "x178Zn9",
  "02Q4ha1",
  "g325Q7b",
  "U0274tO",
  "2n922Zn",
  "S7g95v4",
  "xJ210y2",
  "rC22L10",
  "139R8cB",
  "w278I3c",
  "6n3F108",
  "Qu54343",
  "j478U9w",
  "C6w0K74",
  "20r1G7g",
  "gC2551Z",
  "16I62dX",
  "Qd94x74",
  "a9Ny476",
  "f6q70H7",
  "c8t7J67",
  "97N3Q7z",
  "84A30mh",
  "X153Kx5",
  "52X27cd",
  "G13731f",
  "Dxl1047",
  "1PwP219",
  "F3m5m33",
  "U4510sd",
  "3qX821n",
  "Ip181K6",
  "A84M63f",
  "39Hf7h9",
  "w197US2",
  "AN40p01",
  "q729K5e",
  "9K4gA13",
  "22t9Fe2",
  "460C7za",
  "9v3Aw37",
  "s8893wQ",
  "W82nY51",
  "k0783Lw",
  "1Tu08J4",
  "w1i6P22",
  "pK724n5",
  "c55CH93",
  "21bP49C",
  "24kx99O",
  "5b239kU",
  "0g0I02s",
  "j94Q90F",
  "8800YTp",
  "2iIQ254",
  "3eAj511",
  "A631rm6",
  "Q20p5s5",
  "0IxR222",
  "y0S29V8",
  "aWr0191",
  "V5u179q",
  "Y21S8r9",
  "b2e729G",
  "d121x4B",
  "15HtN73",
  "81h9gK9",
  "m656Tj7",
  "e90KC79",
  "K174j8m",
  "598Zq6v",
  "2dK5U81",
  "f9Q95p5",
  "B3pS462",
  "10n8H97",
  "18Jw27m",
  "Nd065y9",
  "u8N233I",
  "ga8344S",
  "3g62j0B",
  "00k86AY",
  "g3V16m8",
  "8857GIf",
  "64R54Lw",
  "61R31vj",
  "V3706vz",
  "3y6m0Z3",
  "007UBb9",
  "99h57MO",
  "90C8R6p",
  "s15Hl08",
  "l34P06a",
  "3Q6qL76",
  "n1812X6",
  "558D2cn",
  "oT604R7",
  "9Ini343",
  "X44w4R9",
  "cq271Q9",
  "24WU9p7",
  "G332l0O",
  "709z6hC",
  "6lj621Y",
  "p1711EJ",
  "43W84z8",
  "1rV2x52",
];

export const lots = [
  "0101",
  "0102",
  "0111",
  "0112",
  "0121",
  "0122",
  "0131",
  "0132",
  "0141",
  "0142",
  "0151",
  "0152",
  "0161",
  "0162",
  "0171",
  "0172",
  "0201",
  "0202",
  "0211",
  "0212",
  "0221",
  "0222",
  "0231",
  "0232",
  "0241",
  "0242",
  "0251",
  "0252",
  "0261",
  "0262",
  "0271",
  "0272",
  "0301",
  "0302",
  "0311",
  "0312",
  "0313",
  "0321",
  "0322",
  "0323",
  "0331",
  "0332",
  "0333",
  "0341",
  "0342",
  "0343",
  "0351",
  "0352",
  "0353",
  "0361",
  "0362",
  "0371",
  "0372",
  "0401",
  "0402",
  "0411",
  "0412",
  "0421",
  "0422",
  "0431",
  "0432",
  "0441",
  "0442",
  "0451",
  "0452",
  "0461",
  "0462",
  "0471",
  "0472",
  "0501",
  "0502",
  "0511",
  "0512",
  "0521",
  "0522",
  "0531",
  "0532",
  "0541",
  "0542",
  "0551",
  "0552",
  "0561",
  "0562",
  "0571",
  "0572",
  "0601",
  "0602",
  "0611",
  "0612",
  "0621",
  "0622",
  "0631",
  "0632",
  "0641",
  "0642",
  "0651",
  "0652",
  "0661",
  "0662",
  "0671",
  "0672",
  "0700",
  "0701",
  "0702",
  "0703",
  "0711",
  "0712",
  "0713",
  "0721",
  "0722",
  "0723",
  "0731",
  "0732",
  "0733",
  "0741",
  "0742",
  "0743",
  "0751",
  "0752",
  "0753",
  "0761",
  "0762",
  "0763",
  "0771",
  "0772",
];

export const getLot = (locId) => {
  console.log(lots[codes.indexOf(locId)]);
  return lots[codes.indexOf(locId)] ? lots[codes.indexOf(locId)] : "admin";
};
