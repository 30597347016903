import React, { useState, useContext } from "react";
import classes from "./Layout.module.css";
// Primary Components
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
// Routing
import { withRouter } from "react-router-dom";
// Context
import { AuthContext } from "../../contexts/AuthContext";

const Layout = (props) => {
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const sideDrawerCloseHandler = () => {
    setShowSideDrawer(false);
  };

  const sideDrawerToggleHandler = () => {
    setShowSideDrawer(!showSideDrawer);
  };

  return (
    <React.Fragment>
      <Toolbar
        drawerToggleClicked={sideDrawerToggleHandler}
        currentUser={currentUser}
      />
      <SideDrawer open={showSideDrawer} close={sideDrawerCloseHandler} />
      <main className={classes.Content}>{props.children}</main>
    </React.Fragment>
  );
};

export default withRouter(Layout);
