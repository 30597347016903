import React from "react";
import classes from "./CalendarMobile.module.css";
// Components
import CalendarCard from "../CalendarCard/CalendarCard";
import CalendarModal from "../CalendarModal/CalendarModal";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CalendarMobile = ({
  CalendarData,
  selectedCard,
  setSelectedCard,
  show,
  setShow,
}) => {
  const classesMUI = useStyles();
  return (
    <div className={classes.Calendar}>
      <div className={classes.CalendarTitle}>
        <p>
          <span>Le calendrier</span> des travaux
        </p>
      </div>
      <div className={classes.Main}>
        <div className={classes.CardsList}>
          {CalendarData.map((step, index) => (
            <CalendarCard
              emoji={step.emoji}
              title={step.title}
              index={index}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              key={step.id}
              setShow={setShow}
            />
          ))}
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesMUI.modal}
          open={show}
          onClose={() => setShow(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Modal}>
              <CalendarModal step={CalendarData[selectedCard]} />
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default CalendarMobile;
