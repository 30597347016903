import React from "react";
import classes from "./SideDrawer.module.css";
// UI Components
import Backdrop from "../../UI/Backdrop/Backdrop";
// Navigation
import NavigationItems from "../NavigationItems/NavigationItems";

const SideDrawer = (props) => {
  let attachedClasses = props.open
    ? [classes.SideDrawer, classes.Open]
    : [classes.SideDrawer, classes.Close];

  return (
    <React.Fragment>
      <Backdrop show={props.open} clicked={props.close} />
      <div className={attachedClasses.join(" ")}>
        {/* <div>
          <Logo />
        </div> */}

        <div>
          <NavigationItems />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SideDrawer;
