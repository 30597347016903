import React from "react";
import classes from "./ReclamationContent.module.css";
// MUI
import { Button } from "@material-ui/core";
// utils
import {
  submitReclamation,
  loadUserReclamations,
} from "../../../../utils/TravauxUtils";

const StepFive = ({
  currentUser,
  setStep,
  setShow,
  tagOne,
  tagTwo,
  title,
  description,
  pictures,
  setReclamations,
}) => {
  const handleSubmit = () => {
    const reclamation = {
      tagOne: tagOne,
      tagTwo: tagTwo,
      title: title,
      description: description,
      pictures: pictures,
    };
    submitReclamation(reclamation, currentUser.uid);
    setShow(false);
    setStep(0);
    loadUserReclamations(setReclamations, currentUser.uid);
  };
  return (
    <div className={classes.StepContent}>
      <div className={classes.RecapTags}>
        {tagOne && <p>{tagOne}</p>}
        {tagOne && <p>{tagTwo}</p>}
      </div>
      <div className={classes.RecapTitle}>
        <p style={{ fontWeight: "700", color: "#3B4578", fontSize: ".9rem" }}>
          Le titre de votre demande
        </p>
        <div className={classes.TitleArea}>
          <p>{title}</p>
        </div>
      </div>
      <div className={classes.RecapDescription}>
        <p style={{ fontWeight: "700", color: "#3B4578", fontSize: ".9rem" }}>
          La description de votre demande
        </p>
        <div className={classes.DescriptionArea}>
          <p>{description}</p>
        </div>
      </div>
      <div className={classes.RecapImagesRow}>
        {pictures.map((option, index) => {
          return option.url ? (
            <div key={option.id}>
              <div
                className={classes.ImageUploaded}
                style={{
                  backgroundImage: `url('${option.url}')`,
                }}
              ></div>
            </div>
          ) : null;
        })}
      </div>
      <div className={classes.Buttons}>
        <Button
          color="primary"
          size="medium"
          style={{
            width: "6rem",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
          onClick={() => setStep(1)}
        >
          Retour
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={title.trim() === "" || description.trim() === ""}
          style={{ width: "8rem", color: "white" }}
          onClick={handleSubmit}
        >
          Valider
        </Button>
      </div>
    </div>
  );
};

export default StepFive;
