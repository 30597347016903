import React from "react";
import classes from "./ReclamationContent.module.css";
// MUI
import { Button } from "@material-ui/core";

const StepOne = ({ setStep, setShow }) => {
  return (
    <div className={classes.StepContent}>
      <p>
        Bienvenue dans le cahier des demandes !
        <br />
        <br />
        Si vous souhaitez faire parvenir une demande spécifique aux équipes
        travaux, remplissez cette fiche et vous serez signalés de la prise en
        compte de votre demande.
        <br />
        <br />
        Vous pouvez créer autant de fiches de demande que vous souhaitez, pour
        cela créez une nouvelle fiche de demande.
      </p>
      <div className={classes.IntroImage}>
        <img
          style={{ width: "100%" }}
          alt="salon"
          src={require(`../../../../assets/travaux/reclamation/introReclamation.svg`)}
        />
      </div>
      <div className={classes.Buttons}>
        <Button
          color="primary"
          size="medium"
          style={{
            width: "6rem",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
          onClick={() => setShow(false)}
        >
          Fermer
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ width: "8rem", color: "white" }}
          onClick={() => setStep(1)}
        >
          Commencer
        </Button>
      </div>
    </div>
  );
};

export default StepOne;
