import React, { useState, useContext, useEffect } from "react";
import classes from "./CardAdminReclamation.module.css";
// context
import { AuthContext } from "../../../../contexts/AuthContext";
// MUI
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import CheckIcon from "@material-ui/icons/Check";
// Component
import ModalAdminReclamation from "../../ModalAdminReclamation/ModalAdminReclamation";
// utils
import { loadAllReclamations } from "../../../../utils/TravauxUtils";

const CardAdminReclamation = ({ reclamations, setReclamations }) => {
  const { currentUser } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);

  useEffect(() => {
    loadAllReclamations(setReclamations, currentUser.uid);
  }, []);

  if (reclamations) {
    return (
      <>
        <ModalAdminReclamation
          show={show}
          setShow={setShow}
          reclamation={reclamations[selectedCard]}
          setReclamations={setReclamations}
          currentUserUid={currentUser.uid}
        />

        {reclamations.map((reclamation, index) => {
          return (
            <div
              style={reclamation.answer && { border: "3px solid #fcb34a" }}
              className={classes.Card}
              onClick={() => {
                setShow(true);
                setSelectedCard(index);
              }}
              key={reclamation.reclamationId}
            >
              {reclamation.answer && (
                <div className={classes.Notification}>
                  <CheckIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "white",
                      marginBottom: "-.4rem",
                    }}
                  />
                </div>
              )}
              <div className={classes.Thumbnail}>
                <img
                  alt="thumbnail"
                  src={require(`../../../../assets/travaux/reclamation/${
                    reclamation.tagOne === "logement"
                      ? "appMain.svg"
                      : reclamation.tagOne === "immeuble"
                      ? "immMain.svg"
                      : reclamation.tagOne === "résidence"
                      ? "residMain.svg"
                      : "autre_globale.svg"
                  }`)}
                />
              </div>

              <div className={classes.Main}>
                <div className={classes.Titre}>
                  <p>{reclamation.title.substring(0, 58) + "..."}</p>
                </div>
                <div className={classes.Description}>
                  <span>
                    {" "}
                    {reclamation.description.substring(0, 58) + "..."}
                  </span>
                </div>

                <div className={classes.TagContainer}>
                  <div
                    className={classes.Tag}
                    style={{ backgroundColor: "#3B4578" }}
                  >
                    <EditOutlinedIcon style={{ fontSize: ".8rem" }} />
                    <p>Réclamation</p>
                  </div>
                </div>
              </div>
              <div className={classes.Icon}>
                <KeyboardArrowRightRoundedIcon style={{ fontSize: "1.8rem" }} />
              </div>
            </div>
          );
        })}
      </>
    );
  } else {
    return (
      <div
        style={{
          margin: "1rem auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
};

export default CardAdminReclamation;
