export const ResultatsReducer = (state, action) => {
  switch (action.type) {
    case "SET_PARTICIPANTS":
      return {
        ...state,
        participants: action.value,
      };

    case "SET_REPONSES":
      return {
        ...state,
        reponses: action.value,
      };

    case "TEST_DISPATCH":
      console.log("dispatch test");
      return state;
    default:
      return state;
  }
};
