import React from "react";
import classes from "./BackButton.module.css";

const backButton = (props) => {
  return (
    <button className={classes.BackButton} onClick={props.clicked}>
      <i className="fas fa-chevron-left"></i>
    </button>
  );
};

export default backButton;
