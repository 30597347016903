import React, { useReducer, createContext } from "react";
import { ResultatsReducer } from "../reducers/ResultatsReducer";

export const ResultatsContext = createContext();

const ResultatsContextProvider = ({ children }) => {
  const [resultatsData, dispatch] = useReducer(ResultatsReducer, {
    participants: 0,
    reponses: 0,
  });

  return (
    <ResultatsContext.Provider value={{ resultatsData, dispatch }}>
      {children}
    </ResultatsContext.Provider>
  );
};

export default ResultatsContextProvider;
