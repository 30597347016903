export const ContactReducer = (state, action) => {
  switch (action.type) {
    case "SET_EMAIL":
      return {
        ...state,
        email: {
          ...state.email,
          value: action.email,
          valid: action.valid,
        },
      };

    case "SET_MESSAGE":
      return {
        ...state,
        message: {
          ...state.message,
          value: action.message,
          valid: action.valid,
        },
      };

    case "MESSAGE_SENT":
      return {
        ...state,
        email: {
          ...state.email,
          value: "",
          valid: false,
        },
        message: {
          ...state.email,
          value: "",
          valid: false,
        },
        helperText: {
          type: action.helperType,
          message: action.helperMessage,
        },
      };

    case "SET_ERROR":
      return {
        ...state,
        helperText: {
          type: action.helperType,
          message: action.helperMessage,
        },
      };

    default:
      return state;
  }
};
