import React from "react";
import classes from "./Input.module.css";

const input = (props) => {
  let inputElement = null;

  switch (props.elementType) {
    case "Input":
      inputElement = (
        <input
          className={props.styledInput ? props.styledInput : classes.Input}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;

    case "textarea":
      inputElement = (
        <textarea
          className={
            props.styledInput ? props.styledInput : classes.TextareaElement
          }
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;

    case "radio":
      inputElement = (
        <form onChange={props.changed} className={classes.RadioFormElement}>
          {props.elementConfig.options.map((option) => {
            return (
              <div key={option.value} className={classes.RadioDivElement}>
                <input
                  type="radio"
                  name={props.elementConfig.name}
                  value={option.value}
                  id={option.id}
                  checked={option.checked}
                />
                <label htmlFor={option.id}>
                  <span>{option.displayValue}</span>
                </label>
              </div>
            );
          })}
        </form>
      );
      break;

    default:
      inputElement = (
        <input
          className={classes.InputElement}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  return (
    <div className={classes.InputGroup}>
      {inputElement}
      <span className={classes.Highlight}></span>
      <span className={classes.Bar}></span>
      <label className={classes.InputLabel}>{props.children}</label>
    </div>
  );
};

export default input;
