import React, { useState, useEffect } from "react";
import classes from "./ReclamationContent.module.css";
import { storage } from "../../../../config/firebase";
// MUI
import { Button, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import ControlPointRoundedIcon from "@material-ui/icons/ControlPointRounded";

const StepFour = ({
  setStep,
  title,
  setTitle,
  description,
  setDescription,
  pictures,
  setPictures,
}) => {
  // const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleEditPicture = (inputId) => {
    const fileInput = document.getElementById(inputId);
    fileInput.click();
  };

  const handleChange = async (e, index, id) => {
    if (e.target.files[0]) {
      handleUpload(e.target.files[0], index, id);
    }
  };

  const handleUpload = (image, index, id) => {
    const imageExtension = image.name.split(".")[
      image.name.split(".").length - 1
    ];
    const imageName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    const uploadTask = storage.ref(`images/${imageName}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(imageName)
          .getDownloadURL()
          .then((url) => {
            // push URL to array of objects
            let newArr = [...pictures];
            newArr[index] = { id: id, url: url };
            setPictures(newArr);
          });
      }
    );
  };

  return (
    <div className={classes.StepContent}>
      <div className={classes.Question}>
        <div className={classes.QuestionTitle}>
          <HelpOutlineRoundedIcon style={{ fontSize: 40, color: "#3B4578" }} />
          <p>
            Donnez un titre à votre demande.{" "}
            <span style={{ fontSize: ".7rem" }}>*obligatoire</span>
          </p>
        </div>
        <div className={classes.TitleField}>
          <TextField
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            variant="outlined"
            inputProps={{
              maxLength: 100,
            }}
            label=""
            placeholder="Donnez ici un titre à votre demande."
            style={{ marginBottom: "0.8em", width: "100%" }}
            color="primary"
          />
        </div>
        <div className={classes.QuestionTitle}>
          <HelpOutlineRoundedIcon style={{ fontSize: 40, color: "#3B4578" }} />
          <p>
            Décrivez votre demande.{" "}
            <span style={{ fontSize: ".7rem" }}>*obligatoire</span>
          </p>
        </div>
        <div className={classes.DescriptionField}>
          <TextField
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            variant="outlined"
            inputProps={{
              maxLength: 500,
            }}
            placeholder="Ecrivez ici votre demande (500 caractères maximum.)"
            label=""
            style={{ marginBottom: "0.8em", width: "100%" }}
            color="primary"
            multiline
            rows={4}
          />
        </div>
        <div className={classes.QuestionTitle}>
          <HelpOutlineRoundedIcon style={{ fontSize: 40, color: "#3B4578" }} />
          <p>
            Ajouter des photos à votre demande.{" "}
            <span style={{ fontSize: ".7rem" }}>(facultatif)</span>
          </p>
        </div>
        <div className={classes.ImagesRow}>
          {pictures.map((option, index) => (
            <div key={option.id}>
              <input
                type="file"
                id={option.id}
                hidden="hidden"
                onChange={(e) => handleChange(e, index, option.id)}
              />

              {option.url ? (
                <div
                  className={classes.ImageUploaded}
                  style={{
                    backgroundImage: `url('${option.url}')`,
                  }}
                ></div>
              ) : (
                <div
                  className={classes.ImagePlaceholder}
                  onClick={() => handleEditPicture(option.id)}
                >
                  <ControlPointRoundedIcon
                    style={{ fontSize: "3rem" }}
                    color="primary"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.Buttons}>
        <Button
          color="primary"
          size="medium"
          style={{
            width: "6rem",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
          onClick={() => setStep(1)}
        >
          Retour
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={title.trim() === "" || description.trim() === ""}
          style={{ width: "8rem", color: "white" }}
          onClick={() => setStep(4)}
        >
          Valider
        </Button>
      </div>
    </div>
  );
};

export default StepFour;
