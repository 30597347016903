export const LoginReducer = (state, action) => {
  switch (action.type) {
    case "SET_REFLOC":
      return {
        ...state,
        refLoc: action.element,
      };

    case "SET_NUMTEL":
      return {
        ...state,
        numTel: action.element,
      };

    case "SET_CODESMS":
      return {
        ...state,
        codeSMS: action.element,
      };

    case "SET_CGUCHECKED":
      return {
        ...state,
        CGU: action.element,
      };

    case "SET_FORMISVALID":
      return {
        ...state,
        formIsValid: action.formIsValid,
      };

    case "SET_TELSHOW":
      const prevTelShow = state.telShow;
      return {
        ...state,
        telShow: !prevTelShow,
      };

    case "SET_CGUSHOW":
      const prevCGUShow = state.CGUShow;
      return {
        ...state,
        CGUShow: !prevCGUShow,
      };

    case "SET_REFSHOW":
      const prevRefShow = state.refShow;
      return {
        ...state,
        refShow: !prevRefShow,
      };

    case "SET_SMSSHOW":
      const prevSMSSHOW = state.SMSShow;
      return {
        ...state,
        SMSShow: !prevSMSSHOW,
      };

    case "SET_CONFRESULT":
      return {
        ...state,
        confResult: action.confResult,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading,
      };

    case "SET_ISNEWUSER":
      return {
        ...state,
        isNewUser: action.isNewUser,
      };

    default:
      return state;
  }
};
