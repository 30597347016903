import React from "react";
import classes from "./OvalButton.module.css";

const OvalButton = (props) => {
  return (
    <button className={classes.OvalButton} onClick={props.clicked}>
      {props.children}
    </button>
  );
};

export default OvalButton;
