import { motion } from "framer-motion";
import styled from "styled-components";

export default styled(motion.div)`
  background-color: ${(props) => (props.opened ? "#E2E2E2" : "#f5a623")};
  width: ${(props) => (!props.open ? "20px" : "30px")};
  height: ${(props) => (!props.open ? "20px" : "30px")};
  border-radius: ${(props) => (!props.open ? "20px" : "30px")};
  border: 3px solid white;
  position: absolute;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* top: ${(props) => props.top};
  left: ${(props) => props.left}; */
  cursor: pointer;
`;
