import React from "react";
import classes from "./SubmitButton.module.css";

const SubmitButton = (props) => {
  let buttonClasses = [classes.SubmitButton];
  let buttonText = props.children;

  if (props.isValid) {
    buttonClasses.push(classes.Valid);
  } else {
    buttonClasses.push(classes.Invalid);
  }

  if (props.loading) {
    buttonClasses = [classes.SubmitButton, classes.Clicked];
    buttonText = "En cours...";
  }
  //   console.log(classes.Valid);
  //   buttonClasses = buttonClasses.join(" ");

  return (
    <button
      ref={props.reference}
      onClick={props.clicked}
      disabled={!props.isValid}
      className={buttonClasses.join(" ")}
    >
      <span className={classes.ButtonText}>{buttonText}</span>
      <i className="fas fa-paper-plane"></i>
    </button>
  );
};

export default SubmitButton;
