import React from "react";
import classes from "./ProfilePage.module.css";
// Images
import BonHomme from "../../assets/images/bonhomme.png";
// Primary Components
import QuestionOne from "../../components/ProfileQuestions/QuestionOne/QuestionOne";
import QuestionTwo from "../../components/ProfileQuestions/QuestionTwo/QuestionTwo";
import QuestionThree from "../../components/ProfileQuestions/QuestionThree/QuestionThree";
import QuestionFive from "../../components/ProfileQuestions/QuestionFive/QuestionFive";
import QuestionSix from "../../components/ProfileQuestions/QuestionSix/QuestionSix";
// UI Components
import OvalButton from "../../components/UI/Buttons/OvalButton/OvalButton";

const ProfilePage = () => {
  return (
    <div className={classes.ProfilePage}>
      <div className={classes.HeaderImageWrapper}>
        <img src={BonHomme} alt="Bon Homme" />
      </div>
      <h1>Votre profil</h1>
      <div className={classes.InfosPerso}>
        <h3>Vos informations personnelles</h3>
        <QuestionOne />
        <QuestionTwo />
      </div>
      <div className={classes.InfosLogement}>
        <h3>Votre logement</h3>
        <QuestionThree />
        <QuestionFive />
        <QuestionSix />
      </div>
      <div className={classes.SubmitButton}>
        <OvalButton>VALIDER</OvalButton>
      </div>
    </div>
  );
};

export default ProfilePage;
