import React from "react";
import classes from "./NavigationItems.module.css";
// Primary components
import NavigationItem from "./NavigationItem/NavigationItem";
import LongButton from "../../UI/Buttons/LongButton/LongButton";
// Firebase Config
import app from "../../../config/firebase";

const NavigationItems = () => {
  return (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/" exact>
        Accueil
      </NavigationItem>

      <NavigationItem link="/my-profile">Mon profil</NavigationItem>

      <div className={classes.Participer}>
        <LongButton>Participer</LongButton>
      </div>

      <div>
        <LongButton clicked={() => app.auth().signOut()}>
          Se déconnecter
        </LongButton>
      </div>
    </ul>
  );
};

export default NavigationItems;
