import React, { useContext } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Wrapper from "../elements/CardWrapper";
import Title from "../elements/CardTitle";
import Description from "../elements/CardDescription";
import NextButton from "../elements/NextButton";
import BackButton from "../elements/BackButton";
import CardModal from "../layouts/CardModal";
// Router
import { useParams } from "react-router-dom";
// Contexts
import { TravauxContext } from "../../contexts/TravauxContext";
import PinsType from "./PinsType";
import BeforeAfterType from "./BeforeAfterType";
import VideoYoutubeType from "./VideoYoutubeType";
import RadioType from "./RadioType";

const CardContent = (props) => {
  const { travauxData, dispatch } = useContext(TravauxContext);
  const { cards, selectedCard, showCard, filters } = travauxData;
  const { width, currentUser } = props;
  let { cardIndex, contentIndex } = useParams();
  let contentData = cards[cardIndex].content[contentIndex];
  let contentLength = cards[cardIndex].content.length;
  let cardsLength = cards.length;
  let cardID = cards[cardIndex].id;

  let content;
  switch (contentData.type) {
    case "pins":
      content = (
        <PinsType
          contentData={contentData}
          cardID={cardID}
          dispatch={dispatch}
          contentIndex={contentIndex}
          cardIndex={cardIndex}
          contentLength={contentLength}
          cardsLength={cardsLength}
        />
      );
      break;
    case "beforeAfter":
      content = (
        <BeforeAfterType
          contentData={contentData}
          cardID={cardID}
          dispatch={dispatch}
          contentIndex={contentIndex}
          cardIndex={cardIndex}
          contentLength={contentLength}
          cardsLength={cardsLength}
        />
      );
      break;
    case "videoYoutube":
      content = (
        <VideoYoutubeType
          contentData={contentData}
          cardID={cardID}
          dispatch={dispatch}
          contentIndex={contentIndex}
          cardIndex={cardIndex}
          contentLength={contentLength}
          cardsLength={cardsLength}
        />
      );
      break;
    case "radio":
      content = (
        <RadioType
          contentData={contentData}
          cardID={cardID}
          dispatch={dispatch}
          contentIndex={contentIndex}
          cardIndex={cardIndex}
          contentLength={contentLength}
          cardsLength={cardsLength}
        />
      );
      break;
    default:
      content = <p>Wrong Content Type</p>;
      console.error("Wrong Content Type");
      break;
  }

  return (
    <CardModal width={width} dispatch={dispatch} show={true}>
      <>{content}</>
    </CardModal>
  );
};

export default CardContent;
