import React from "react";
import classes from "./Radio.module.css";

const Radio = (props) => {
  return (
    <React.Fragment>
      <p className={classes.FormTitle}>{props.children}</p>
      <form className={classes.Form}>
        {props.elementConfig.options.map((option) => {
          return (
            <div key={option.value} className={classes.FormElement}>
              <input
                type="radio"
                name={props.elementConfig.name}
                value={option.value}
                onChange={props.changed}
                id={option.id}
                checked={option.checked}
              />
              <label htmlFor={option.id}>{option.displayValue}</label>
            </div>
          );
        })}
      </form>
    </React.Fragment>
  );
};

export default Radio;
