import React, { useContext } from "react";
import classes from "./QuestionTwo.module.css";
// MUI COMPONENTS
import Dropdown from "../../muiComponents/Dropdown/Dropdown";
// CONTEXTS
import { ProfileSetupContext } from "../../../contexts/ProfileSetupContext";

const QuestionTwo = () => {
  const { profileSetupData, dispatch } = useContext(ProfileSetupContext);
  return (
    <div className={classes.QuestionTwo}>
      <p>Quelle est votre année de naissance ?</p>
      <Dropdown
        value={profileSetupData.birthYear.value}
        handleChange={(e) =>
          dispatch({ type: "SET_BIRTHYEAR", birthYear: e.target.value })
        }
      />
    </div>
  );
};

export default QuestionTwo;
