import React, { useState, useContext } from "react";
import classes from "./ProfileSetup.module.css";
// Routing
import { Redirect } from "react-router-dom";
// UI Elements
import NextButton from "../../components/UI/Buttons/NextButton/NextButton";
import BackButton from "../../components/UI/Buttons/BackButton/BackButton";
import CircularProgressBar from "../../components/UI/CircularProgressBar/CircularProgressBar";
// MUI
import CircularProgress from "@material-ui/core/CircularProgress";
// Questions Components
import QuestionOne from "../../components/ProfileQuestions/QuestionOne/QuestionOne";
import QuestionTwo from "../../components/ProfileQuestions/QuestionTwo/QuestionTwo";
import QuestionThree from "../../components/ProfileQuestions/QuestionThree/QuestionThree";
import QuestionFour from "../../components/ProfileQuestions/QuestionFour/QuestionFour";
import QuestionFive from "../../components/ProfileQuestions/QuestionFive/QuestionFive";
import QuestionSix from "../../components/ProfileQuestions/QuestionSix/QuestionSix";
import QuestionSeven from "../../components/ProfileQuestions/QuestionSeven/QuestionSeven";
import QuestionEight from "../../components/ProfileQuestions/QuestionEight/QuestionEight";
// Contexts
import { AuthContext } from "../../contexts/AuthContext";
import { ProfileSetupContext } from "../../contexts/ProfileSetupContext";
// Utils
import { addLocInfo } from "../../utils/ProfileUtils";

const ProfileSetup = () => {
  const [etape, setEtape] = useState(1);
  const { currentUser } = useContext(AuthContext);
  const { profileSetupData } = useContext(ProfileSetupContext);

  // Questions Components List
  const questions = [
    <QuestionOne />,
    <QuestionTwo />,
    <QuestionFour />,
    <QuestionFive />,
    <QuestionSix />,
  ];

  //Bouton next Hanlder
  const nextClickHandler = () => {
    const nextEtape = etape + 1;
    setEtape(nextEtape);
  };

  //Bouton previous Handler
  const previousClickHandler = () => {
    const previousEtape = etape - 1;
    setEtape(previousEtape);
  };

  // Handle the finale submit at step 6
  const submitHandler = () => {
    addLocInfo(profileSetupData, currentUser.uid);
    setEtape(6);
  };

  // Rediriger vers les travaux à la fin du questionnaire
  if (etape === 6) {
    return <Redirect to="/travaux" />;
  }

  return (
    <div className={classes.Wrapper}>
      <div className={classes.ProfileSetupContainer}>
        <div className={classes.ProfileSetup}>
          <div className={classes.ImgWrapper}>
            <img
              className={classes.LoginImg}
              src={require("../../assets/images/undraw_personal_data.svg")}
              alt="Profile Setup"
            />
          </div>

          <h1 className={classes.Title}>
            Quelques questions pour mieux vous connaître
          </h1>

          {/* <div className={classes.CircularProgress}>
            <CircularProgress variant="determinate" value={(etape / 6) * 100} />
          </div> */}
          {/* <div className={classes.CircularProgress}>
            <CircularProgressBar etape={etape} />
          </div> */}

          <div
            className={classes.QuestionContainer}
            style={{ marginTop: "2rem" }}
          >
            {questions[etape - 1]}
          </div>

          <div className={classes.ButtonsContainer}>
            <div className={classes.Buttons}>
              {etape > 1 ? (
                <div className={classes.Button}>
                  <BackButton clicked={previousClickHandler} />
                </div>
              ) : null}

              {etape < 5 ? (
                <div className={classes.Button}>
                  <NextButton clicked={nextClickHandler} />
                </div>
              ) : null}

              {etape === 5 ? <NextButton clicked={submitHandler} /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetup;
