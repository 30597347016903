import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const CardIntroWrapper = styled(motion.section)`
  background: linear-gradient(180deg, #ffffff 0%, #f5efe7 100%);
  padding: 2rem 2rem;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media screen and (min-width: 910px) {
    height: fit-content;
    flex-grow: 1;
  }
`;

const CardMainWrapper = styled(motion.section)`
  background: linear-gradient(180deg, #969696 22.92%, #646464 100%), #6ca1c8;
  background-blend-mode: overlay, normal;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: fit-content;
  flex-grow: 1;
  /* @media screen and (min-width: 910px) {
    height: fit-content;
    flex-grow: 1;
  } */
`;

const Wrapper = ({ children, variant }) => {
  let wrapper;
  switch (variant) {
    case "cardIntro":
      wrapper = (
        <CardIntroWrapper
          variant="cardIntro"
          layoutId="wrapper"
          initial={{ borderRadius: 0 }}
          animate={{ borderRadius: 40 }}
          transition={{ duration: 0.5 }}
        >
          {children}
        </CardIntroWrapper>
      );
      break;
    case "cardMain":
      wrapper = (
        <CardMainWrapper
          variant="cardIntro"
          layoutId="wrapper"
          initial={{ borderRadius: 40 }}
          animate={{ borderRadius: 0 }}
          transition={{ duration: 0.5 }}
        >
          {children}
        </CardMainWrapper>
      );
      break;
    default:
      wrapper = <p>Wrong Wrapper Variant</p>;
      console.error("Wrong wrapper variant");
      break;
  }

  return wrapper;
};

export default Wrapper;
