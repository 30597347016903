import React, { useState } from "react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import confettiCannons from "../../assets/lottie/confetti-cannons.json";
import styled from "styled-components";
import Title from "../elements/CardTitle";
import Switch from "../elements/Switch/Switch";
import NextButton from "../elements/NextButton";
import Wrapper from "../elements/CardWrapper";
import Description from "../elements/CardDescription";
import Pin from "../elements/ActionPin";
import LinearProgress from "@material-ui/core/LinearProgress";

const Image = styled.img`
  width: 35rem;
  @media screen and (min-width: 910px) {
    width: 100%;
  }
`;

const ImageWrapper = styled(motion.div)`
  max-width: 40rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -3rem;
  margin-top: -3rem;
  @media screen and (min-width: 910px) {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const SwitchWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const PinPulse = styled(motion.div)`
  background-color: #f5a623;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  /* top: ${(props) => props.top};
  left: ${(props) => props.left}; */
  position: absolute;
  /* top: -10px;
  left: -9px; */
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
`;
const PinsContainer = styled(motion.div)`
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const ArrowTop = styled.span`
  position: absolute;
  width: 0px;
  height: 0px;
  border-right: 120px solid transparent;
  border-bottom: 30px solid #f9f9f9;
  border-left: 120px solid transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
`;
const CloseButton = styled(motion.p)`
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-top: 1rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

const LottieWrapper = styled(motion.div)`
  position: absolute;
  max-width: 20rem;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);

  pointer-events: none;
`;

const Progress = styled(motion.div)`
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: #ffffff;
  text-align: center;
`;
const PinsInstructions = styled.span`
  display: flex;
  justify-content: center;
  font-style: normal;
  margin-top: 0.5rem;
  margin-left: -0.8rem;
  font-weight: 400;
  font-size: 0.8rem;
  color: #ffffff;
  text-align: center;
`;

const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

const BeforeAfterType = ({
  dispatch,
  contentData,
  cardID,
  cardIndex,
  contentIndex,
  contentLength,
  cardsLength,
}) => {
  const { title, description, pins, openedPinsCount, imageBefore, imageAfter } =
    contentData;
  const [isToggled, setIsToggled] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [selectedPin, setSelectedPin] = useState(null);

  const pinClickHandler = (pinIndex) => {
    dispatch({
      type: "SET_OPENEDPIN",
      cardID: cardID,
      cardIndex: cardIndex,
      contentIndex: contentIndex,
      pinIndex: pinIndex,
    });
    dispatch({
      type: "SET_OPENEDPINSCOUNT",
      cardID: cardID,
      cardIndex: cardIndex,
      contentIndex: contentIndex,
      pinIndex: pinIndex,
    });
    setSelectedPin(pinIndex);
    setShowDescription(true);
  };

  return (
    <Wrapper
      variant="cardMain"
      layoutId="wrapper"
      initial={{ borderRadius: 40 }}
      animate={{ borderRadius: 0 }}
      transition={{ duration: 0.5 }}
    >
      <LinearProgress
        variant="determinate"
        style={{ width: "85%", margin: "1rem auto" }}
        color="secondary"
        value={(contentIndex / contentLength) * 100}
      />
      <Title
        layoutId="title"
        transition={{ duration: 0.5 }}
        color="#fff"
        style={{ paddingLeft: "2rem" }}
      >
        {title}
      </Title>

      <ImageWrapper
        layoutId="imageIllustration"
        drag="x"
        dragConstraints={{ left: -50, right: 50 }}
      >
        <Image
          alt="La résidentialisation"
          src={require(`../../assets/travaux/${
            !isToggled ? imageBefore : imageAfter
          }`)}
        />
        {isToggled
          ? pins.map((pin, index) => (
              <PinsContainer top={pin.top} left={pin.left} key={pin.pinId}>
                {!pin.open ? (
                  <PinPulse
                    top={pin.top}
                    left={pin.left}
                    initial={{ opacity: 0.75, scale: 0.5 }}
                    animate={{ opacity: 0, scale: 2 }}
                    transition={{ repeat: Infinity, duration: 1.5 }}
                  />
                ) : null}
                <Pin
                  layoutId={pin.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.1 }}
                  top={pin.top}
                  left={pin.left}
                  onClick={() => pinClickHandler(index)}
                  opened={pin.opened}
                  open={index === selectedPin}
                />
              </PinsContainer>
            ))
          : null}
      </ImageWrapper>

      {showDescription ? (
        <>
          <Description
            variant="cardMain"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <ArrowTop />
            {pins[selectedPin].description}
          </Description>
          <CloseButton
            onClick={() => {
              setShowDescription(false);
              setSelectedPin(null);
            }}
          >
            Fermer
          </CloseButton>
        </>
      ) : openedPinsCount < pins.length ? (
        <>
          <SwitchWrapper
            layoutId="switch"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            <Switch
              isToggled={isToggled}
              onToggle={() => setIsToggled(!isToggled)}
            />
          </SwitchWrapper>
          <Progress
            layoutId="progress"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {openedPinsCount}/{pins.length} informations explorées
            <PinsInstructions>
              Explorez en cliquant sur{" "}
              <div
                style={{
                  marginLeft: ".3rem",
                }}
              >
                <Pin
                  layoutId="pin"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.1 }}
                  opened={false}
                  open={false}
                />
              </div>
            </PinsInstructions>
          </Progress>
        </>
      ) : (
        <>
          <SwitchWrapper
            layoutId="switch"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            <Switch
              isToggled={isToggled}
              onToggle={() => setIsToggled(!isToggled)}
            />
          </SwitchWrapper>
          <LottieWrapper>
            <Lottie animationData={confettiCannons} loop={false} />
          </LottieWrapper>

          <ButtonWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <NextButton
              href={
                parseInt(contentIndex) < contentLength - 1
                  ? `/travaux/${cardIndex}/${parseInt(contentIndex) + 1}`
                  : "/travaux"
              }
            >
              Valider
            </NextButton>
          </ButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default BeforeAfterType;
