import React from "react";

export const travauxCardsData = [
  {
    id: "i1",
    type: "newLayout",
    titleStrong: "L’amélioration et la mise",
    titleWeak: "en sécurité de l’immeuble",
    description:
      "Ces travaux d’amélioration visent à remettre aux normes l’ensemble de la résidence: des parties communes des immeubles jusqu’aux logements. Ces améliorations réglementaires vous feront gagner en sécurité afin de  minimiser les risques d’incendie ou d’insalubrité. Pour plus de détails, suivez le guide en vidéo !",
    thumbnail: "i1-thumb.png",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux d’amélioration visent à remettre aux normes l’ensemble de la résidence: des parties communes des immeubles jusqu’aux logements. Ces améliorations réglementaires vous feront gagner en sécurité afin de  minimiser les risques d’incendie ou d’insalubrité. Pour plus de détails, suivez le guide en vidéo !",
        title: "L’amélioration et la mise en sécurité de l’immeuble",
        video: {
          url: "https://youtu.be/CTbIEl6BA7Y",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i2",
    type: "newLayout",
    titleStrong: "Les travaux",
    titleWeak: "sur l’enveloppe du bâtiment",
    description:
      "Des logements où on a trop froid l’hiver et trop chaud l’été, ce n’est bon pour personne! Ni pour votre confort, ni pour votre porte-monnaie et encore moins pour la planète, car une mauvaise note thermique est révélatrice d’importantes consommations et factures énergétiques peu soutenables. Pour remédier à cela, nous pouvons agir sur l’enveloppe du bâtiment.",
    thumbnail: "Isolation-combles-2.png",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "pins",
        title: "Les travaux sur l’enveloppe du bâtiment",
        description: (
          <>
            Les <strong>combles</strong> sont isolés, ce qui se traduit
            directement par une hausse de confort pour les logements situés en
            dessous.
          </>
        ),
        imageUrl: "Isolation-combles-2.png",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description: "Charpente de la toiture.",
            top: "26%",
            left: "50%",
            opened: false,
          },
          {
            pinId: "pin-2",
            description: "Isolant.",
            top: "45%",
            left: "25%",
            opened: false,
          },
          {
            pinId: "pin-3",
            description: "Couverture de l’isolant.",
            top: "41%",
            left: "38%",
            opened: false,
          },
          {
            pinId: "pin-4",
            description: (
              <>
                Les <strong>combles</strong> sont isolés, ce qui se traduit
                directement par une hausse de confort pour les logements situés
                en dessous.
              </>
            ),
            top: "80%",
            left: "40%",
            opened: false,
          },
        ],
      },
      // {
      //   contentId: "content-2",
      //   type: "pins",
      //   title: "Les travaux sur l’enveloppe du bâtiment",
      //   description: (
      //     <>
      //       Une{" "}
      //       <strong>
      //         isolation par l'extérieur sur les murs pignons est posée
      //       </strong>{" "}
      //       pour gagner en confort dans les logements qui y sont accolés.
      //       (Soumis à accord des propriétaires voisins.)
      //     </>
      //   ),
      //   imageUrl: "Isolation-pignons.svg",
      //   openedPinsCount: 0,
      //   pins: [
      //     {
      //       pinId: "pin-1",
      //       description: "Ossature.",
      //       top: "35%",
      //       left: "50%",
      //       opened: false,
      //     },
      //     {
      //       pinId: "pin-2",
      //       description: "Isolant.",
      //       top: "40%",
      //       left: "40%",
      //       opened: false,
      //     },
      //     {
      //       pinId: "pin-3",
      //       description: "Revêtement extérieur de façade.",
      //       top: "50%",
      //       left: "60%",
      //       opened: false,
      //     },
      //     {
      //       pinId: "pin-4",
      //       description: (
      //         <>
      //           Une{" "}
      //           <strong>
      //             isolation par l'extérieur sur les murs pignons est posée
      //           </strong>{" "}
      //           pour gagner en confort dans les logements qui y sont accolés.
      //           (Soumis à accord des propriétaires voisins.)
      //         </>
      //       ),
      //       top: "85%",
      //       left: "40%",
      //       opened: false,
      //     },
      //   ],
      // },
      // {
      //   contentId: "content-3",
      //   type: "pins",
      //   title: "Les travaux sur l’enveloppe du bâtiment",
      //   description: (
      //     <>
      //       Une isolation <strong>des plafonds des cave</strong> fait gagner les
      //       logements de rez de chaussée et les halls en confort et performance.
      //     </>
      //   ),
      //   imageUrl: "Isolation-plafond-cave.svg",
      //   openedPinsCount: 0,
      //   pins: [
      //     {
      //       pinId: "pin-1",
      //       description: "Charpente de la toiture.",
      //       top: "43%",
      //       left: "33%",
      //       opened: false,
      //     },
      //     {
      //       pinId: "pin-2",
      //       description: "Isolant.",
      //       top: "52%",
      //       left: "50%",
      //       opened: false,
      //     },
      //     {
      //       pinId: "pin-3",
      //       description: "Couverture de l’isolant.",
      //       top: "30%",
      //       left: "50%",
      //       opened: false,
      //     },
      //     {
      //       pinId: "pin-4",
      //       description: (
      //         <>
      //           Une isolation <strong>des plafonds des cave</strong> fait gagner
      //           les logements de rez de chaussée et les halls en confort et
      //           performance.
      //         </>
      //       ),
      //       top: "70%",
      //       left: "40%",
      //       opened: false,
      //     },
      //   ],
      // },
      {
        contentId: "content-4",
        type: "pins",
        title: "Les travaux sur l’enveloppe du bâtiment",
        description: (
          <>
            Les travaux d'<strong>extensions des halls</strong> créent{" "}
            <strong>des sas thermiques</strong> qui évitent les pertes de
            chaleur et par la même occasion les courants d’air.
          </>
        ),
        imageUrl: "Isolation-sas-thermique.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description: (
              <>
                Les travaux d'<strong>extensions des halls</strong> créent{" "}
                <strong>des sas thermiques</strong> qui évitent les pertes de
                chaleur et par la même occasion les courants d’air.
              </>
            ),
            top: "40%",
            left: "38%",
            opened: false,
          },
        ],
      },

      {
        contentId: "content-5",
        type: "beforeAfter",
        title: "Les travaux sur l’enveloppe du bâtiment",
        description:
          "Les menuiseries extérieures PVC seront remplacées par des menuiseries bois: mieux isolées et plus lumineuses.",
        value: false,
        imageBefore: "Fenêtres-PVC.svg",
        imageAfter: "Fenêtres-bois.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description:
              "Les menuiseries extérieures PVC seront remplacées par des menuiseries bois: mieux isolées et plus lumineuses.",
            top: "58%",
            left: "35%",
            opened: false,
          },
        ],
      },
      {
        contentId: "content-6",
        type: "pins",
        title: "Les travaux sur l’enveloppe du bâtiment",
        description:
          "Ces travaux, tout en respectant les exigences du plan climat de la Ville de Paris, visent à atteindre la classe énergétique C. (Aujourd’hui E)",
        imageUrl: "Classe-énergétique.svg",
        openedPinsCount: 0,
        pins: [],
      },
    ],
    tags: "Illustration",
    group: "a",
  },
  {
    id: "i3",
    type: "newLayout",
    titleStrong: "Les équipements énergétiques",
    titleWeak: "de votre résidence",
    description:
      "Votre résidence est ancienne,  et ce qui était la norme en termes de gestion des énergies (eau, chaleur, air) à l’époque de sa construction ne rentre plus dans les critères de performance d’aujourd’hui. Votre résidence est ancienne,  et ce qui était la norme en termes de gestion des énergies (eau, chaleur, air) à l’époque de sa construction ne rentre plus dans les critères de performance d’aujourd’hui. En cause, des logements extrêmement énergivores qui impactent votre confort de vie, votre porte-monnaie et l’environnement. Pour remédier à ce constat, la réhabilitation a notamment pour objectif d’améliorer la performance des équipements de chauffage et eau chaude et de limiter les déperditions de chaleur. Outre son effet bénéfique à la qualité de l’air intérieur des appartements (l’air est renouvelé en permanence car l’air vicié est aspiré et remplacé par un air neuf en provenance de l’extérieur), la VMC (ventilation mécanique contrôlée) réduit le débit des entrées d’air (grilles d’aération dans les fenêtres au minimum nécessaire pour contrôler via les installations de VMC.",
    thumbnail: "i3-thumb.png",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Votre résidence est ancienne, et ce qui était la norme en termes de gestion des énergies (eau, chaleur, air) à l’époque de sa construction ne rentre plus dans les critères de performance d’aujourd’hui. En cause, des logements extrêmement énergivores qui impactent votre confort de vie, votre porte-monnaie et l’environnement. Pour changer les choses, des interventions en profondeur sur les systèmes de distribution d’énergie seront faites lors de la réhabilitation.",
        title: "Les équipements énergétiques de votre résidence.",
        video: {
          url: "https://youtu.be/gC3XuoH33Zw",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i4-1a",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-1A-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-1A-Avant.svg",
        imageAfter: "Pieces-humides-1A-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Remplacement des équipements sanitaires.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "52%",
            left: "64%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "40%",
            left: "37%",
            opened: false,
          },
          {
            pinId: "pin-10",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "29%",
            left: "49%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-1b",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-1B-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-1B-Avant.svg",
        imageAfter: "Pieces-humides-1B-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Remplacement des équipements sanitaires.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "40%",
            left: "37%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "62%",
            left: "64%",
            opened: false,
          },
          {
            pinId: "pin-10",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "78%",
            left: "46%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-1c",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-1C-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-1C-Avant.svg",
        imageAfter: "Pieces-humides-1C-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Remplacement des équipements sanitaires.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "62%",
            left: "64%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "24%",
            left: "47%",
            opened: false,
          },
          {
            pinId: "pin-9",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "52%",
            left: "89%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-2a",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-2A-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-2A-Avant.svg",
        imageAfter: "Pieces-humides-2A-Apres-mod.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "32%",
            left: "74%",
            opened: false,
          },
          {
            pinId: "pin-1",
            description: (
              <>
                <ul>
                  <li>Remplacement des équipements sanitaires.</li>
                </ul>{" "}
              </>
            ),
            top: "58%",
            left: "24%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),

            top: "52%",
            left: "44%",
            opened: false,
          },
          {
            pinId: "pin-6",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "62%",
            left: "54%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-2b",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-2B-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-2B-Avant.svg",
        imageAfter: "Pieces-humides-2B-Apres-mod.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "73%",
            left: "32%",
            opened: false,
          },
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Remplacement des équipements sanitaires.</li>
                </ul>{" "}
              </>
            ),
            top: "22%",
            left: "64%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "44%",
            left: "47%",
            opened: false,
          },
          {
            pinId: "pin-6",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "62%",
            left: "64%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-3",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-3-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-3-Avant.svg",
        imageAfter: "Pieces-humides-3-Apres-mod.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Remplacement des équipements sanitaires.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "58%",
            left: "34%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "24%",
            left: "47%",
            opened: false,
          },
          {
            pinId: "pin-6",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "52%",
            left: "80%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-4",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-4-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-4-Avant.svg",
        imageAfter: "Pieces-humides-4-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Remplacement des équipements sanitaires.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "55%",
            left: "60%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-5",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-5-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-5-Avant.svg",
        imageAfter: "Pieces-humides-5-Apres-mod.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Remplacement des équipements sanitaires.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "44%",
            left: "65%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "63%",
            left: "34%",
            opened: false,
          },
          {
            pinId: "pin-6",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "78%",
            left: "32%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-6",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-6-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-6-Avant.svg",
        imageAfter: "Pieces-humides-6-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Remplacement des équipements sanitaires.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "61%",
            left: "44%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "50%",
            left: "70%",
            opened: false,
          },
          {
            pinId: "pin-6",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "36%",
            left: "64%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-7",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-7-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-7-Avant.svg",
        imageAfter: "Pieces-humides-7-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "54%",
            left: "55%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-8",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-8-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-8-Avant.svg",
        imageAfter: "Pieces-humides-8-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "59%",
            left: "45%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-9",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-9-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-9-Avant.svg",
        imageAfter: "Pieces-humides-9-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Remplacement des équipements sanitaires.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "73%",
            left: "58%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "48%",
            left: "37%",
            opened: false,
          },
          {
            pinId: "pin-6",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "62%",
            left: "28%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-11",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-11-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-11-Avant.svg",
        imageAfter: "Pieces-humides-11-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "73%",
            left: "58%",
            opened: false,
          },
          {
            pinId: "pin-1",
            description: (
              <>
                <ul>
                  <li>Remplacement des équipements sanitaires.</li>
                </ul>{" "}
              </>
            ),
            top: "30%",
            left: "58%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "54%",
            left: "47%",
            opened: false,
          },
          {
            pinId: "pin-6",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "48%",
            left: "60%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4-12",
    type: "newLayout",
    titleStrong: "La restructuration",
    titleWeak: "des pièces humides",
    description:
      "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
    thumbnail: "Pieces-humides-12-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "La configuration des pièces humides (Cuisines, salles de bain et WC) date d’une époque où l’on ne vivait pas de la même manière son logement et où les exigences des résidents n’étaient pas les mêmes. Les travaux consistent à revoir l’agencement de ces pièces afin de les rendre plus pratiques pour votre quotidien.",
        title: "La restructuration des pièces humides",
        value: false,
        imageBefore: "Pieces-humides-12-Avant.svg",
        imageAfter: "Pieces-humides-12-Apres.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-5",
            description: (
              <>
                <ul>
                  <li>Pose d’une nouvelle faïence de salle de bain</li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Peinture de la salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans les WC et salle de bain.
                  </li>
                  <li style={{ marginTop: "1rem" }}>
                    Remplacement des équipements sanitaires.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "33%",
            left: "63%",
            opened: false,
          },
          {
            pinId: "pin-7",
            description: (
              <>
                <ul>
                  <li>
                    Peinture de la cuisine et pose d’un nouvel équipement de
                    cuisine.
                  </li>{" "}
                  <li style={{ marginTop: "1rem" }}>
                    Pose d’un nouveau sol souple dans la cuisine.
                  </li>
                </ul>{" "}
              </>
            ),
            top: "62%",
            left: "28%",
            opened: false,
          },
          {
            pinId: "pin-6",
            description:
              "Pose d’un panneau coulissant entre le salon et la cuisine.",
            top: "73%",
            left: "38%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i5",
    type: "newLayout",
    titleStrong: "Les travaux",
    titleWeak: "de la salle de bain",
    description:
      "L’un des gros chantiers de cette réhabilitation consiste à reconfigurer l’ensemble des pièces humides. Cette reconfiguration est aussi l’occasion de moderniser l’ensemble des équipements sanitaires de votre salle de bain et rafraîchir ses sols, murs et plafonds.",
    thumbnail: "i5-thumb.png",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "L’un des gros chantiers de cette réhabilitation consiste à reconfigurer l’ensemble des pièces humides. Cette reconfiguration est aussi l’occasion de moderniser l’ensemble des équipements sanitaires de votre salle de bain et rafraîchir ses sols, murs et plafonds.",
        title: "Les travaux de la salle de bain.",
        video: {
          url: "https://youtu.be/VQb0t2bAfHI",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    group: "a",
  },
  {
    id: "i6",
    type: "newLayout",
    titleStrong: "Les travaux",
    titleWeak: "de la cuisine",
    description:
      "L’un des gros chantiers de cette réhabilitation consiste à reconfigurer l’ensemble des pièces humides. Cette reconfiguration est aussi l’occasion de moderniser l’ensemble des équipements de votre cuisine et rafraîchir ses sols, murs et plafonds.",
    thumbnail: "i6-thumb.png",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "L’un des gros chantiers de cette réhabilitation consiste à reconfigurer l’ensemble des pièces humides. Cette reconfiguration est aussi l’occasion de moderniser l’ensemble des équipements de votre cuisine et rafraîchir ses sols, murs et plafonds.",
        title: "Les travaux de la cuisine.",
        video: {
          url: "https://youtu.be/umgz9E-1q1U",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    group: "a",
  },
  {
    id: "i7",
    type: "newLayout",
    titleStrong: "Les travaux",
    titleWeak: "sur les parties communes",
    description:
      "Les parties communes seront entièrement revues pour leur donner une seconde jeunesse, suivez le guide! Notre voyage commencera par l’entrée de votre immeuble et se clôturera par l’entrée de votre logement, du porte à porte en somme.",
    thumbnail: "Partie-Communes.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "pins",
        title: "Les travaux sur les parties communes",
        description:
          "Les parties communes seront entièrement revues pour leur donner une seconde jeunesse, suivez le guide! Notre voyage commencera par l’entrée de votre immeuble et se clôturera par l’entrée de votre logement, du porte à porte en somme.",
        imageUrl: "Isolation-sas-thermique.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description: "Création d’un sas thermique.",
            top: "25%",
            left: "35%",
            opened: false,
          },
          {
            pinId: "pin-2",
            description: "Les portes des halls sont conservées.",
            top: "50%",
            left: "65%",
            opened: false,
          },
          {
            pinId: "pin-3",
            description: "Les boîtes aux lettres sont déplacées dans le sas.",
            top: "25%",
            left: "62%",
            opened: false,
          },
          {
            pinId: "pin-4",
            description:
              "Un sol souple type béton ciré remplace le carrelage au sol.",
            top: "83%",
            left: "35%",
            opened: false,
          },
        ],
      },
      {
        contentId: "content-2",
        type: "pins",
        title: "Les travaux sur les parties communes",
        description:
          "Le reste des parties communes est remis à neuf et la porte d’entrée dans votre logement remplacée pour être conforme aux exigences de sécurité.",
        imageUrl: "Partie-Communes.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description: "Ponçage et vitrification des marches en bois.",
            top: "80%",
            left: "60%",
            opened: false,
          },
          {
            pinId: "pin-2",
            description: "Peinture des murs et plafonds.",
            top: "35%",
            left: "20%",
            opened: false,
          },
          {
            pinId: "pin-3",
            description: "Remplacement porte palière.",
            top: "33%",
            left: "73%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Illustration",
    group: "a",
  },
  {
    id: "i8",
    type: "newLayout",
    titleStrong: "Les locaux",
    titleWeak: "vélos/poussettes",
    description:
      "Pour mieux répondre aux évolutions des ménages et aux besoins des locataires, un nouveau local vélo et poussette sera créé dans le bâtiment A.",
    thumbnail: "velo-poussette-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-5",
        type: "beforeAfter",
        title: "Les locaux vélos/poussettes",
        description:
          "Pour mieux répondre aux évolutions des ménages et de notre temps qui veut qu’on sera de plus en plus à se déplacer en vélo, un nouveau local vélo et poussette sera créé dans le bâtiment A.",
        value: false,
        imageBefore: "velo-poussette-Avant.svg",
        imageAfter: "velo-poussette-Après.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description:
              "L’agencement de l’ancien local vélo et poussette est retravaillé pour le rendre plus pratique et mieux adapté.",
            top: "57%",
            left: "43%",
            opened: false,
          },
          {
            pinId: "pin-2",
            description:
              "Un nouveau local vélo est créé ici à l’intérieur du bâtiment.",
            top: "46%",
            left: "66%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    group: "a",
  },
  {
    id: "i9",
    type: "newLayout",
    titleStrong: "Les locaux",
    titleWeak: "ordures ménagères et tri sélectif",
    description:
      "Les locaux ordures ménagères et tri sélectif sont revus et corrigés, pour les rendre plus pratiques, moins étriqués et mieux répartis. Deux locaux fermés pour les ordures ménagères et tri sont prévus afin de permettre un accès facilité, plus propre et sécurisé. Un local est prévu près de la loge des gardiens, l’autre sera installé en bas de la résidence dans un local qui sera complètement rénové.",
    thumbnail: "Locaux-a-poubelle-Avant.svg",
    submitted: false,
    content: [
      {
        contentId: "content-5",
        type: "beforeAfter",
        title: "Les locaux ordures ménagères et tri sélectif",
        description:
          "Et pour mieux répondre aux évolutions des ménages et de notre temps qui veut qu’on sera de plus en plus à se déplacer en vélo, un nouveau local vélo et poussette sera créé dans le bâtiment A.",
        value: false,
        imageBefore: "Locaux-a-poubelle-Avant.svg",
        imageAfter: "Locaux-a-poubelle-Après.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description: "Un local complètement rénové",
            top: "52%",
            left: "37%",
            opened: false,
          },
          {
            pinId: "pin-2",
            description: "Un local prévu près de la loge des gardiens",
            top: "46%",
            left: "64%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    group: "a",
  },
  {
    id: "i10",
    type: "newLayout",
    titleStrong: "Les cours",
    titleWeak: "intérieures",
    description:
      "Les cours intérieures de votre résidence sont étudiées pour accueillir de nouveaux aménagements qui les rendront plus agréables. Aidez-nous à mieux comprendre vos attentes sur ces espaces. Pour vous exprimer cliquez sur personnaliser.",
    thumbnail: "Cours-intérieures.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Les cours intérieures",
        question:
          "Pour mieux comprendre vos attentes sur ces espaces, nous vous proposons de répondre à une série de questions. Souhaitez-vous avois sur ces espaces des jardinières partagées pour des plantations ?",
        imageUrl: "i11-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "i11-q1-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "i11-q1-2.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Les cours intérieures",
        question:
          "Seriez-vous prêt à les utiliser et participer à leur entretien ?",
        imageUrl: "i11-q2-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "i11-q2-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "i11-q2-2.svg",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Les cours intérieures",
        question: "Quels types de plantations verrez-vous dans ces jardins ?",
        imageUrl: "i11-q3-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Des plantes aromatiques.",
            value: "rep1",
            image: "i11-q3-1.svg",
          },
          {
            label: "Des cultures potagères.",
            value: "rep2",
            image: "i11-q3-2.svg",
          },
          {
            label: "Des petits fruits (type groseilliers, fraisiers).",
            value: "rep3",
            image: "i11-q3-3.svg",
          },
          {
            label: "Des fleurs.",
            value: "rep4",
            image: "i11-q3-4.svg",
          },
          {
            label: "Des plantes mixtes.",
            value: "rep5",
            image: "i11-q3-5.svg",
          },
        ],
      },
      {
        contentId: "content-4",
        type: "radio",
        title: "Les cours intérieures",
        question:
          "Pourriez-vous participer à des ateliers de jardinage avec d’autres locataires ?",
        imageUrl: "i11-q4-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "i11-q4-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "i11-q4-2.svg",
          },
        ],
      },
      {
        contentId: "content-4",
        type: "radio",
        title: "Les cours intérieures",
        question:
          "Pourriez-vous participer à un dispositif de compostage ? (épluchures de fruits / légumes)",
        imageUrl: "i11-q5-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "i11-q5-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "i11-q5-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    group: "a",
  },
  {
    id: "i11",
    type: "newLayout",
    titleStrong: "La cour",
    titleWeak: "centrale",
    description:
      "La cour centrale est l’allée intérieure bitumée de votre résidence. Pour rendre cette allée plus agréable qu’elle ne l’est aujourd’hui, les concepteurs veulent végétaliser les pieds d’immeuble et remplacer le bitume par d’autres matériaux.",
    thumbnail: "Cour-centrale.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Les cours intérieures",
        question:
          "Pour mieux comprendre vos attentes sur ces espaces, nous vous proposons de répondre à une série de questions. Souhaitez-vous avois sur ces espaces des jardinières partagées pour des plantations ?",
        imageUrl: "i11-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "i11-q1-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "i11-q1-2.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Les cours intérieures",
        question:
          "Seriez-vous prêt à les utiliser et participer à leur entretien ?",
        imageUrl: "i11-q2-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "i11-q2-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "i11-q2-2.svg",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Les cours intérieures",
        question: "Quels types de plantations verrez-vous dans ces jardins ?",
        imageUrl: "i11-q3-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Des plantes aromatiques.",
            value: "rep1",
            image: "i11-q3-1.svg",
          },
          {
            label: "Des cultures potagères.",
            value: "rep2",
            image: "i11-q3-2.svg",
          },
          {
            label: "Des petits fruits (type groseilliers, fraisiers).",
            value: "rep3",
            image: "i11-q3-3.svg",
          },
          {
            label: "Des fleurs.",
            value: "rep4",
            image: "i11-q3-4.svg",
          },
          {
            label: "Des plantes mixtes.",
            value: "rep5",
            image: "i11-q3-5.svg",
          },
        ],
      },
      {
        contentId: "content-4",
        type: "radio",
        title: "Les cours intérieures",
        question:
          "Pourriez-vous participer à des ateliers de jardinage avec d’autres locataires ?",
        imageUrl: "i11-q4-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "i11-q4-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "i11-q4-2.svg",
          },
        ],
      },
      {
        contentId: "content-4",
        type: "radio",
        title: "Les cours intérieures",
        question:
          "Pourriez-vous participer à un dispositif de compostage ? (épluchures de fruits / légumes)",
        imageUrl: "i11-q5-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "i11-q5-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "i11-q5-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    group: "a",
  },
  {
    id: "i12",
    type: "newLayout",
    titleStrong: "Les travaux",
    titleWeak: "sur le jardin",
    description:
      "Le jardin est la bande végétale qui sépare le bâtiment C du voisin. Cette bande qui est aujourd’hui peu valorisée, peut être retravaillée pour amener de nouveaux usages aux locataires.",
    thumbnail: "i12-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Les travaux sur le jardin.",
        question:
          "Pour concevoir ce jardin les concepteurs vous demandent votre avis. Quel sorte de jardin irait le mieux à cet endroit selon vous ?",
        imageUrl: "i12-q1-intro.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Des jardins partagés.",
            value: "rep1",
            image: "i12-q1-1.svg",
          },
          {
            label: "Des jardins pédagogiques.",
            value: "rep2",
            image: "i12-q1-2.svg",
          },
          {
            label: "Des jardins paysagés.",
            value: "rep3",
            image: "i12-q1-3.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "i12-q1-4.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    group: "a",
  },
  {
    id: "i13",
    type: "newLayout",
    titleStrong: "L’accompagnement",
    titleWeak: "des locataires",
    description:
      "Un accompagnement spécifique des locataires est prévu pour les personnes fragiles, pour certains locataires dont la configuration du logement nécessite un relogement temporaire et enfin pour toutes les questions liées au désamiantage. (Amiante et plomb)",
    thumbnail: "i13-q1.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "pins",
        title: "L’accompagnement des locataires.",
        description: (
          <>
            Un accompagnement spécifique des locataires est prévu pour les
            personnes fragiles, pour certains locataires dont la configuration
            du logement nécessite un relogement temporaire et enfin pour toutes
            les questions liées au désamiantage. (Amiante et plomb)
          </>
        ),
        imageUrl: "i13-thumb.svg",
        openedPinsCount: 0,
        pins: [],
      },
    ],
    tags: "Personnalisable",
    group: "a",
  },
];
