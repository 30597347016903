import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled.div`
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    height: 10vh;
    justify-content: center;
    align-items: center;
    color: #a0a0a0;
  }
`;

const BackButton = (props) => {
  return (
    <Button>
      <Link to={props.href}>{props.children}</Link>
    </Button>
  );
};

export default BackButton;
