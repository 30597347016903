import React, { useState, useEffect, useRef, useContext } from "react";
import classes from "./Travaux.module.css";
// Firebase
import app from "../../config/firebase";
// Contexts
import { TravauxContext } from "../../contexts/TravauxContext";
import { AuthContext } from "../../contexts/AuthContext";
// Routing
import PrivateRoute from "../../config/PrivateRoute";
// Routing
import { Switch, useLocation } from "react-router-dom";
// Utils
import { loadAnswers } from "../../utils/TravauxUtils";
// Components
import Modal from "../../components/UI/Modal/Modal";
import Toolbar from "../../components/Travaux/Navigation/Toolbar/Toolbar";
import Filter from "../../components/Travaux/Filter/Filter";
import CardList from "../../components/Travaux/CardList/CardList";
import Footer from "../../components/Travaux/Footer/Footer";
import CardModal from "../../components/Travaux/CardModal/CardModal";
// Cards
import NonConcertableLayoutOne from "../../components/Travaux/NonConcertableLayouts/NonConcertableLayoutOne/NonConcertableLayoutOne";
// MUI
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
// animation
import { motion } from "framer-motion";
// Routing
import { Link } from "react-router-dom";
// Styled Components
import CardIntro from "../../styledComponents/templates/CardIntro";
import CardContent from "../../styledComponents/templates/CardContent";
// Transitions
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";

const scrollToRef = (ref) => window.scrollTo(0, 150);

const Travaux = () => {
  const location = useLocation();
  const { travauxData, dispatch } = useContext(TravauxContext);
  const { currentUser } = useContext(AuthContext);
  const { cards, selectedCard, showCard, filters } = travauxData;
  const [claims, setClaims] = useState();

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = useState(window.innerWidth);

  // Card Element
  // const cardElement = (
  //   <React.Fragment>
  //     {selectedCard !== "" && cards[selectedCard].type === "concertable" ? (
  //       <CardModal show={showCard}>
  //         <ConcertableLayoutOne
  //           card={cards[selectedCard]}
  //           dispatch={dispatch}
  //           width={width}
  //           currentUser={currentUser}
  //         />
  //       </CardModal>
  //     ) : selectedCard !== "" &&
  //       cards[selectedCard].type === "nonConcertable" ? (
  //       <CardModal show={showCard}>
  //         <NonConcertableLayoutOne
  //           card={cards[selectedCard]}
  //           dispatch={dispatch}
  //           width={width}
  //           currentUser={currentUser}
  //         />
  //       </CardModal>
  //     ) : null}
  //   </React.Fragment>
  // );
  const cardElement = (
    <React.Fragment>
      {selectedCard !== "" && cards[selectedCard].type === "newLayout" ? (
        <CardIntro
          card={cards[selectedCard]}
          dispatch={dispatch}
          width={width}
          currentUser={currentUser}
          show={showCard}
        />
      ) : selectedCard !== "" &&
        cards[selectedCard].type === "nonConcertableSpecial" ? (
        <NonConcertableLayoutOne
          card={cards[selectedCard]}
          dispatch={dispatch}
          width={width}
          currentUser={currentUser}
          show={showCard}
        />
      ) : null}
    </React.Fragment>
  );

  useEffect(() => {
    // Get custom claims
    currentUser &&
      app
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => setClaims(idTokenResult.claims))
        .catch((err) => console.log(err));

    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Load data
    // loadAnswers(currentUser.uid, dispatch);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  if (width < 768) {
    return (
      <React.Fragment>
        <div ref={myRef}></div>
        {/* <Modal
          show={introModalShow}
          backgroundColor="#F3F2F0"
          clicked={() => setIntroModalShow(false)}
        >
          <IntroModal clicked={() => setIntroModalShow(false)} />
        </Modal> */}

        {/* {cardElement} */}
        <Toolbar />
        <motion.div className={classes.SubHeader}>
          <div className={classes.HomeLink}>
            <DescriptionOutlinedIcon
              style={{ fontSize: "1.5rem", marginTop: "-.1rem" }}
            />
            <Link to="/">Revenir à la description du projet</Link>
          </div>
          {/* <Filter filters={filters} dispatch={dispatch} /> */}
        </motion.div>
        <motion.div className={classes.Travaux}>
          <div className={classes.Main}>
            <div className={classes.CardListTitle}>
              <p>
                <span>Consulter</span> les travaux et poster vos demandes{" "}
              </p>
              <span
                className={classes.Emoji}
                role="img"
                style={{ fontSize: "2rem", border: "none" }}
                aria-label="emoji"
              >
                👇
              </span>
            </div>
            <CardList
              filters={filters}
              cards={cards}
              dispatch={dispatch}
              executeScroll={executeScroll}
              claims={claims}
            />
            <div className={classes.HomeLink}>
              <DescriptionOutlinedIcon
                style={{
                  fontSize: "1.5rem",
                  marginTop: "-.1rem",
                  color: "#333333",
                }}
              />
              <Link to="/" style={{ color: "#333333" }}>
                Revenir à la description du projet
              </Link>
            </div>
          </div>
          <Footer />

          <PrivateRoute path="/travaux/:cardIndex" exact>
            <CardIntro width={width} currentUser={currentUser} />
          </PrivateRoute>
          <PrivateRoute path="/travaux/:cardIndex/:contentIndex" exact>
            <CardContent width={width} currentUser={currentUser} />
          </PrivateRoute>
        </motion.div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/* <Modal
          show={introModalShow}
          backgroundColor="#F3F2F0"
          clicked={() => setIntroModalShow(false)}
        >
          <IntroModal clicked={() => setIntroModalShow(false)} />
        </Modal> */}
        {/* {cardElement} */}
        <Toolbar />
        <motion.div className={classes.SubHeader}>
          <div className={classes.HomeLink}>
            <DescriptionOutlinedIcon />
            <Link to="/">Revenir à la description du projet</Link>
          </div>
          {/* <Filter filters={filters} dispatch={dispatch} /> */}
        </motion.div>
        <motion.div className={classes.Travaux}>
          <div className={classes.Main}>
            <div className={classes.CardListTitle}>
              <p>
                <span>Consulter</span> les travaux et poster vos demandes
              </p>
              <span
                className={classes.Emoji}
                role="img"
                style={{ fontSize: "2rem", border: "none" }}
                aria-label="emoji"
              >
                👇
              </span>
            </div>
            <div className={classes.MainCards}>
              <div className={classes.List}>
                <CardList
                  filters={filters}
                  cards={cards}
                  dispatch={dispatch}
                  executeScroll={executeScroll}
                  claims={claims}
                />
              </div>
            </div>
            <div className={classes.HomeLink}>
              <DescriptionOutlinedIcon
                style={{
                  fontSize: "1.5rem",
                  marginTop: "-.1rem",
                  color: "#333333",
                }}
              />

              <Link to="/" style={{ color: "#333333" }}>
                Revenir à la description du projet
              </Link>
            </div>
          </div>
        </motion.div>

        <AnimateSharedLayout>
          <AnimatePresence initial={false} exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <PrivateRoute path="/travaux/:cardIndex" exact>
                <CardIntro width={width} currentUser={currentUser} />
              </PrivateRoute>
              <PrivateRoute path="/travaux/:cardIndex/:contentIndex" exact>
                <CardContent width={width} currentUser={currentUser} />
              </PrivateRoute>
            </Switch>
          </AnimatePresence>
        </AnimateSharedLayout>

        <Footer />
      </React.Fragment>
    );
  }
};

export default Travaux;
