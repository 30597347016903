import React from "react";
import classes from "./NextButton.module.css";

const nextButton = (props) => {
  return (
    <button className={classes.NextButton} onClick={props.clicked}>
      <i className="fas fa-chevron-right"></i>
    </button>
  );
};

export default nextButton;
