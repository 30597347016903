import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// Routing
import { BrowserRouter as Router } from "react-router-dom";
// MUI
import muiTheme from "./theme/muiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import "./assets/Futura_Extra_Bold.otf";

ReactDOM.render(
  <ThemeProvider theme={muiTheme}>
    <React.Fragment>
      <Router>
        <App />
      </Router>
    </React.Fragment>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
