import React, { useContext } from "react";
// UI components
import Input from "../../UI/Input/Input";
// CONTEXTS
import { ProfileSetupContext } from "../../../contexts/ProfileSetupContext";

const QuestionOne = (props) => {
  const { profileSetupData, dispatch } = useContext(ProfileSetupContext);
  return (
    <div>
      <Input
        {...profileSetupData.name}
        value={profileSetupData.name.value}
        changed={(e) => dispatch({ type: "SET_NAME", name: e.target.value })}
      >
        Votre prénom
      </Input>
      <Input
        {...profileSetupData.lastName}
        value={profileSetupData.lastName.value}
        changed={(e) =>
          dispatch({ type: "SET_LASTNAME", lastName: e.target.value })
        }
      >
        Votre nom
      </Input>
    </div>
  );
};

export default QuestionOne;
