import React, { useContext } from "react";
// CONTEXTS
import { ProfileSetupContext } from "../../../contexts/ProfileSetupContext";
// MUI COMPONENTS
import Radio from "../../muiComponents/Radio/Radio";

const QuestionThree = (props) => {
  const { profileSetupData, dispatch } = useContext(ProfileSetupContext);
  return (
    <div>
      <Radio
        {...profileSetupData.flatSize}
        value={profileSetupData.flatSize.value}
        changed={(e) =>
          dispatch({ type: "SET_FLATSIZE", flatSize: e.target.value })
        }
      >
        Quelle est la taille de votre logement ?
      </Radio>
    </div>
  );
};

export default QuestionThree;
