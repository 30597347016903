import React from "react";
import { TextField } from "@material-ui/core";

const CommentInput = ({
  comment,
  dispatch,
  cardID,
  contentIndex,
  cardIndex,
  label,
}) => {
  return (
    <TextField
      onChange={(e) =>
        dispatch({
          type: "SET_COMMENT",
          cardID: cardID,
          cardIndex: cardIndex,
          contentIndex: contentIndex,
          comment: e.target.value,
        })
      }
      value={comment}
      variant="outlined"
      label={label}
      style={{ width: "100%" }}
      color="secondary"
    />
  );
};

export default CommentInput;
